import "./navbar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

// MUI Badge
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

// MUI Menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Icon
import { BsFillMoonFill, BsSunFill, BsSearch } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
// import { RiWallet3Fill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi2";
import { FaUserAlt, FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import { MdHome, MdMail } from "react-icons/md";

import DEFAULTPIC from "../../media/images/default.jpg";
import { useContext, useEffect, useRef, useState } from "react";
import { darkModeContext } from "../../context/darkModeContext";

import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { SocketContext } from "../../context/SocketContext";
import { COLORS } from "../../constants/theme";
import Typography from "@mui/material/Typography";

const Navbar = () => {
  const { logout, userDetails, authToken } = useContext(AuthContext);

  const { socketConnect } = useContext(SocketContext);
  // For the dark mode toggle
  const { toggle, darkMode } = useContext(darkModeContext);

  const page = useLocation().pathname.split("/")[1];

  // Theme control
  const activeColor = darkMode === true ? COLORS.dark : COLORS.light;

  const [newNotification, setNewNotification] = useState([]);
  useEffect(() => {
    socketConnect?.on("getNotifications", (data) => {
      setNewNotification((prev) => [...prev, data]);
    });
  }, [socketConnect]);

  // console.log(newNotification);

  // Notifications Counter
  const notificationsCounter = async () => {
    try {
      axios.defaults.withCredentials = true;
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/notifications/notificationsCount/`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const {
    //isLoading,
    data: notificationsCount,
    //error,
  } = useQuery({
    queryKey: ["notifCount"],
    queryFn: notificationsCounter,
  });

  // console.log(notificationsCount);

  // Search bar functionality
  const searchInputRef = useRef(null);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [desktopSearch, setDesktopSearch] = useState(false);
  const openMobileSearch = (event) => {
    setMobileSearch(!mobileSearch);
    if (searchInputRef.current) {
      searchInputRef.current.focus(); // Focusing on the search input
    }
  };

  // for mobile view
  useEffect(() => {
    // Function to handle clicks outside the popup
    const handleClickOutside = (event) => {
      const mobileSearchPopup = document.querySelector(".mobile-search");
      if (mobileSearchPopup && !mobileSearchPopup.contains(event.target)) {
        // Click outside the popup
        setMobileSearch(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // // For desktop view
  useEffect(() => {
    // Function to handle clicks outside the popup
    const clickOutside = (event) => {
      const desktopSearchPopup = document.querySelector(".main-search-result");
      if (desktopSearchPopup && !desktopSearchPopup.contains(event.target)) {
        // Click outside the popup
        setSearch("");
        setFilteredResults(null);
        setDesktopSearch(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", clickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  // Search algo

  // Search all posts
  const searchPosts = async () => {
    try {
      axios.defaults.withCredentials = true;
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/search/postData`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const {
    //isLoading,
    data: posts,
    //error,
  } = useQuery({
    queryKey: ["searchPosts", authToken],
    queryFn: searchPosts,
  });

  // Fetch all users
  const searchUsers = async () => {
    try {
      axios.defaults.withCredentials = true;
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/search/allUser/`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  const {
    //isLoading,
    data: users,
    //error,
  } = useQuery({
    queryKey: ["searchUsers"],
    queryFn: searchUsers,
  });

  const [search, setSearch] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleSearch = (text) => {
    setSearch(text);

    if (users && posts) {
      const filteredUsers = users.filter((user) => {
        const fullName = user.fullName || user.username || "";
        return fullName.toLowerCase().includes(text.toLowerCase());
      });

      const filteredPosts = posts.filter((post) => {
        const postText = post.postText || "";
        return postText.toLowerCase().includes(text.toLowerCase());
      });

      const combinedResults = [...filteredUsers, ...filteredPosts];
      setFilteredResults(combinedResults);
    }
  };

  const clearSearchOpen = () => {
    setSearch("");
    setFilteredResults(null);
    setMobileSearch(false);
    setDesktopSearch(false);
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 2,
      top: 5,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  // Desktop menu dropdown
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="topNavbar">
      <div className="navbarTop">
        <div className="topNavbar-left">
          <Link to="/" className="link">
            <span className="logo">Blikonn</span>
          </Link>
          <div className="search">
            <BsSearch className="search-icon" />
            <BsSearch
              className="mobile-search-btn"
              onClick={openMobileSearch}
            />
            <input
              ref={searchInputRef}
              type="text"
              value={search}
              placeholder="Search Blikonn"
              onChange={(e) => [
                setDesktopSearch(true),
                handleSearch(e.target.value),
              ]}
            />

            {/* For desktop search result */}
            {desktopSearch && (
              <div className="main-search-result ">
                {filteredResults?.length > 0 ? (
                  filteredResults.map((result, index) => (
                    <Link
                      to={
                        result.postText
                          ? `/post/${result.postId}`
                          : `/profile/${result.username}`
                      }
                      state={{ dummyPost: result }}
                      onClick={clearSearchOpen}
                      key={index}
                      style={{
                        color: activeColor.textColor,
                        textDecoration: "none",
                        padding: 10,
                        borderBottom: `1px solid ${activeColor.border}`,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          result.userImage
                            ? `${process.env.REACT_APP_URL}/images/users/profileImages/${result.userImage}`
                            : DEFAULTPIC
                        }
                        alt="userImage"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          marginRight: 10,
                        }}
                      />
                      <div>
                        <Typography
                          variant="body1"
                          style={{ color: activeColor.textColor }}
                        >
                          {result.fullName ? result.fullName : result.username}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: activeColor.textColor }}
                        >
                          {result.postText?.length > 40
                            ? `${result.postText.slice(0, 40)}...`
                            : result.postText}
                        </Typography>
                      </div>
                    </Link>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    style={{ color: activeColor.textColor }}
                  >
                    No result found
                  </Typography>
                )}
              </div>
            )}
          </div>

          {mobileSearch && (
            <div className="mobile-search">
              <div className="search-bar">
                <BsSearch className="trigger-btn" />
                <input
                  type="text"
                  value={search}
                  placeholder="Search Blikonn"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>

              {filteredResults?.length > 0 ? (
                <div className="search-result">
                  {filteredResults.map((result, index) => (
                    <Link
                      to={
                        result.postText
                          ? `/post/${result.postId}`
                          : `/profile/${result.username}`
                      }
                      state={{ dummyPost: result }}
                      onClick={clearSearchOpen}
                      className="mobileLink"
                      key={index}
                    >
                      <div className="search-result-left">
                        {result.userImage ? (
                          <img
                            src={`${process.env.REACT_APP_URL}/images/users/profileImages/${result.userImage}`}
                            alt="userImage"
                          />
                        ) : (
                          <img src={DEFAULTPIC} alt="" />
                        )}
                      </div>
                      <div className="search-result-right">
                        <div className="name">
                          {result.fullName ? result.fullName : result.username}
                        </div>
                        <div className="content">
                          {result.postText?.length > 40
                            ? result.postText.slice(0, 40) + "..."
                            : result.postText}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="search-result">
                  <Typography
                    variant="body1"
                    style={{ color: activeColor.textColor }}
                  >
                    No result found
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Nabar Right */}
        <div className="topNavbar-right">
          <Link
            to={`/notifications/`}
            className="navbar-icon notificaions"
            onClick={() => setNewNotification([])}
          >
            <StyledBadge
              badgeContent={
                newNotification.length > 0 || notificationsCount > 0
                  ? notificationsCount + newNotification.length
                  : null
              }
              color="secondary"
            >
              <NotificationsActiveIcon color="#b80303" sx={{ fontSize: 25 }} />
            </StyledBadge>
          </Link>

          <div className="dark-mode-switch">
            {darkMode ? (
              <BsSunFill onClick={toggle} />
            ) : (
              <BsFillMoonFill onClick={toggle} />
            )}
          </div>

          {/* <Link to="/settings" className="navbar-icon settings">
          <AiFillSetting className="icons" />
        </Link> */}

          <div className="profile-img" onClick={handleClick}>
            {userDetails.userImage ? (
              <img
                src={`${process.env.REACT_APP_URL}/images/users/profileImages/${userDetails.userImage}`}
                alt=""
              />
            ) : (
              <img src={DEFAULTPIC} alt="" />
            )}
          </div>

          {/* Desktop menu */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            autoFocus={false}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                // Target the paper root
                width: 200, // Adjust the width as needed
                backgroundColor: activeColor.cardColor,
                color: activeColor.textColor,
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/profile/${userDetails.username}`);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "color 0.2s, background-color 0.2s",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: activeColor.primary,
                },
              }}
            >
              <FaUserAlt className="icons" />
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/settings/`);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "color 0.2s, background-color 0.2s",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: activeColor.primary,
                },
              }}
            >
              <AiFillSetting className="icons" />
              Settings
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/my-wallet/${userDetails.userId}`);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "color 0.2s, background-color 0.2s",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: activeColor.logo,
                },
              }}
            >
              <RiWallet3Fill className="icons" />
              My wallet
            </MenuItem> */}
            {/* <MenuItem
            onClick={() => {
              handleClose();
              navigate(`/ads/ads-overview/${userDetails.userId}`);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              transition: "color 0.2s, background-color 0.2s",
              "&:hover": {
                color: "#fff",
                backgroundColor: activeColor.logo,
              },
            }}
          >
            <RiAdvertisementFill className="icons" />
            Create Ads
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(`/help-center`);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              transition: "color 0.2s, background-color 0.2s",
              "&:hover": {
                color: "#fff",
                backgroundColor: activeColor.logo,
              },
            }}
          >
            <MdOutlineHelp className="icons" />
            Help And Feedback
          </MenuItem> */}
            <MenuItem
              onClick={() => {
                handleClose();
                logout();
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "color 0.2s, background-color 0.2s",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: activeColor.primary,
                },
              }}
            >
              <FaSignOutAlt className="icons" />
              Log Out
            </MenuItem>
          </Menu>

          <div
            className="profile-img-mobile"
            onClick={() => navigate(`/profile/${userDetails.username}`)}
          >
            {userDetails.userImage ? (
              <img
                src={`${process.env.REACT_APP_URL}/images/users/profileImages/${userDetails.userImage}`}
                alt=""
              />
            ) : (
              <img src={DEFAULTPIC} alt="" />
            )}
          </div>
        </div>
      </div>

      <div className="navbarBottom">
        <Link to="/" className={`links ${page === "" && "navbarLink"}`}>
          <MdHome className="navbar-icon" />
        </Link>
        {/* <Link></Link> */}

        <Link
          to="/messages"
          className={`links ${page === "messages" && "navbarLink"}`}
        >
          <MdMail className="navbar-icon" />
        </Link>

        <Link
          to="/networks/"
          className={`links ${page === "networks" && "navbarLink"}`}
        >
          <HiUserGroup className="navbar-icon" />
        </Link>

        <Link
          to={`/notifications/`}
          className={`navbar-icon notificaions links ${
            page === "notifications" ? "navbarLink" : ""
          }`}
          onClick={() => setNewNotification([])}
        >
          <StyledBadge
            badgeContent={
              newNotification.length > 0 || notificationsCount > 0
                ? notificationsCount + newNotification.length
                : null
            }
            color="secondary"
          >
            <NotificationsActiveIcon color="#b80303" sx={{ fontSize: 28 }} />
          </StyledBadge>
        </Link>

        <Link
          to="/interactions/"
          className={`links ${page === "interactions" && "navbarLink"}`}
        >
          <FaUserFriends className="navbar-icon" />
        </Link>

        <Link
          to="/settings/"
          className={`links ${page === "settings" && "navbarLink"}`}
        >
          <AiFillSetting className="navbar-icon" />
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
