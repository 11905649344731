import axios from "axios";

// Create an Axios instance for making requests to the API
export const makeRequest = axios.create({
  baseURL: process.env.REACT_APP_URL, // Base URL of the API
  withCredentials: true, // Include credentials such as cookies in requests
});

export const makeRequestjwt = axios.create({
  baseURL: process.env.REACT_APP_URL, // Base URL of the API
  withCredentials: true, // Include credentials such as cookies in requests
});

makeRequestjwt.interceptors.request.use(async (config) => {
  // console.log(token)
  const authToken = JSON.parse(localStorage.getItem("authToken")) || null;

  if (authToken) {
    // console.log(authToken);
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }
  return config;
});
