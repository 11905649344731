import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy, useContext } from "react";
import { darkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/authContext";

// General styles
import "./style.scss";

// Fonts
import "./fonts/Rubik-Light.ttf";
import "./fonts/Rubik-Regular.ttf";
import "./fonts/Rubik-Medium.ttf";
import "./fonts/Rubik-SemiBold.ttf";
import "./fonts/Rubik-Bold.ttf";

// Loading Screen
import Overlay from "./components/loadingScreens/Overlay";
import Messaging from "./components/messaging/ChatList";
import ProfileLoading from "./components/loadingScreens/Profile/ProfileLoading";
import ProfileEditLoading from "./components/loadingScreens/Profile/profileEditLoading.jsx/ProfileEditLoading";
import InteractionsLoading from "./components/loadingScreens/interactions/InteractionsLoading";
import { Toaster } from "sonner";
import PostsLoading from "./components/loadingScreens/Posts/PostsLoading";
import ErrorBoundary from "./components/errorComponent/ErrorBoundary";
import Compress from "./pages/compres";
import { Helmet } from "react-helmet";


// Components
const Navbar = lazy(() => import("./components/navBar/Navbar"));
const Leftbar = lazy(() => import("./components/leftBar/Leftbar"));
const Rightbar = lazy(() => import("./components/rightBar/Rightbar"));
const HelpCenterNavbar = lazy(() =>
  import("./components/helpCenterNavBar/HelpCenterNavBar")
);
const HelpCenterLeftbar = lazy(() =>
  import("./components/helpCenterLeftBar/HelpCenterLeftBar")
);
const HelpCenterFooter = lazy(() =>
  import("./components/helpCenterFooter/HelpCenterFooter")
);

// Pages
const Login = lazy(() => import("./pages/login/Login"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const ResetUserPassword = lazy(() =>
  import("./pages/resetPassword/ResetPassword")
);
const VerificationPage = lazy(() =>
  import("./pages/verification/Verification")
);
const RecoverAccount = lazy(() =>
  import("./pages/recoverAccount/RecoverAccount")
);
const CreateNewPassword = lazy(() =>
  import("./pages/createNewPassword/CreateNewPassword")
);
const Home = lazy(() => import("./pages/home/Home"));
const Interactions = lazy(() => import("./pages/interactions/Interactions"));
const Notifications = lazy(() => import("./pages/notifications/Notifications"));

// Networks
const Networks = lazy(() => import("./pages/networks/Networks"));
const CreateNetwork = lazy(() =>
  import("./pages/networks/CreateNetwork/CreateNetwork")
);
const NetworkPage = lazy(() =>
  import("./pages/networks/NetworkPage/NetworkPage")
);
const NetworkDetails = lazy(() =>
  import("./pages/networks/NetworkDetails/NetworkDetails")
);
const EditNetwork = lazy(() =>
  import("./pages/networks/EditNetwork/EditNetwork")
);

const PostDetails = lazy(() => import("./pages/postDetails/PostDetails"));
const Hashtags = lazy(() => import("./pages/hashtags/Hashtags"));
const Menu = lazy(() => import("./pages/menuPage/Menu"));

//Profiles folder
const Chats = lazy(() => import("./pages/profileFolder/chats/Chats"));
const EditProfile = lazy(() =>
  import("./pages/profileFolder/editProfile/EditProfile")
);
const PersonalChat = lazy(() =>
  import("./pages/profileFolder/personalChat/PersonalChat")
);
const Profile = lazy(() => import("./pages/profileFolder/profile/Profile"));
const Wallet = lazy(() => import("./pages/profileFolder/wallet/Wallet"));

//Settings folder
const Settings = lazy(() => import("./pages/settings/Settings"));
const AccountControl = lazy(() =>
  import("./pages/settings/accountControl/AccountControl")
);
const AccountSecurity = lazy(() =>
  import("./pages/settings/accountSecurity/AccountSecurity")
);
const Verifications = lazy(() =>
  import("./pages/settings/verifications/Verifications")
);
const BlockedUsers = lazy(() =>
  import("./pages/settings/blockedUsers/BlockedUsers")
);
const InviteFriends = lazy(() =>
  import("./pages/settings/inviteFriends/InviteFriends")
);
const NotificationsSettings = lazy(() =>
  import("./pages/settings/notificationsSettings/NotificationsSettings")
);
const PrivacySettings = lazy(() =>
  import("./pages/settings/privacySetting/PrivacySettings")
);

const Blogs = lazy(() => import("./pages/blogs/Blogs"));

//Help and info pages
const AboutUs = lazy(() => import("./pages/helpFolder/aboutUs/AboutUs"));
const CookiePolicy = lazy(() =>
  import("./pages/helpFolder/cookiePolicy/CookiePolicy")
);
const ContentsPolicy = lazy(() =>
  import("./pages/helpFolder/contentsPolicy/ContentsPolicy")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/helpFolder/privacyPolicy/PrivacyPolicy")
);

const HelpCenter = lazy(() =>
  import("./pages/helpFolder/helpCenter/HelpCenter")
);
const TermsOfUse = lazy(() =>
  import("./pages/helpFolder/termsOfUse/TermsOfUse")
);
const AdvertisementPolicy = lazy(() =>
  import("./pages/helpFolder/advertisementPolicy/AdvertisementPolicy")
);
const AppTesterPolicy = lazy(() =>
  import("./pages/helpFolder/appTesterPolicy/AppTesterPolicy")
);
const SupportInbox = lazy(() =>
  import("./pages/helpFolder/supportInbox/SupportInbox")
);
const ContactUs = lazy(() => import("./pages/helpFolder/contactUs/ContactUs"));
const AppTesters = lazy(() =>
  import("./pages/getEmails/appTesters/AppTesters")
);
const ConfirmEmails = lazy(() =>
  import("./pages/getEmails/confirmEmails/ConfirmEmails")
);
const HelpCenterMenu = lazy(() =>
  import("./pages/helpFolder/helpCenterMenu/HelpCenterMenu")
);

const OpenBlikonnApp = lazy(() => import("./pages/MobileRedirect"));
// const ReportProblem = lazy(() =>
//   import("./pages/helpFolder/reportAdProblem/ReportProblem")
// );

function App() {
  const { darkMode } = useContext(darkModeContext);
  const { loggedInUser } = useContext(AuthContext);

  // useEffect(() => {
  //   const handleAppUpdateCheck = () => {
  //     if (document.visibilityState === "visible") {
  //       const checkForUpdates = async () => {
  //         // Define the latest version of the application

  //         const latestVersion = "1.0.5"; // Update this with the latest version of your application

  //         // Retrieve the stored version from Local Storage
  //         const storedVersion = localStorage.getItem("appVersion");

  //         // Compare the latest version with the stored version
  //         if (latestVersion !== storedVersion) {
  //           // If a newer version is available, update the stored version
  //           localStorage.setItem("appVersion", latestVersion);

  //           // Reload the page to load the latest version
  //           window.location.reload();
  //         }
  //       };
  //       checkForUpdates();
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleAppUpdateCheck);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleAppUpdateCheck);
  //   };
  // }, []);

  // Create my page view layout

  const Layout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <ErrorBoundary>
          <Messaging />
          <Navbar />
          <div style={{ display: "flex" }}>
            <Leftbar />
            <Toaster
              position="bottom-center"
              richColors
              visibleToasts={1}
              toastOptions={{
                style: {
                  padding: 15,
                },
              }}
            />
            <Outlet />
            <Rightbar />
          </div>
        </ErrorBoundary>
      </div>
    );
  };

  const ProtectedRoute = ({ children }) => {
    if (!loggedInUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  // Create other page view layout
  const HelpLayouts = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <Helmet>
          <title>Blikonn Social: Connect like never before</title>
          <meta
            name="description"
            content="Welcome to meaningful connections. Connect with family and friends around the world!"
          />
          <meta name="keywords" content="social, blikonn, connection" />
          <meta
            property="og:title"
            content="Blikonn Social: Connect like never before"
          />
          <meta
            property="og:description"
            content="Welcome to meaningful connections. Connect with family and friends around the world!"
          />
          <meta
            property="og:image"
            content="https://api.blikonn.com.ng/images/posts/postImage-1722320922100.png"
          />
          <meta property="og:url" content="https://blikonn.com.ng/" />
        </Helmet>
        <HelpCenterNavbar />
        <div style={{ display: "flex" }}>
          <HelpCenterLeftbar />
          <Outlet />
        </div>
        <HelpCenterFooter />
      </div>
    );
  };

  // Apply my view layout to each page links
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Home />
            </Suspense>
          ),
        },
        // Profile =================================================================
        {
          path: "/profile/:username",
          element: (
            <Suspense fallback={<ProfileLoading />}>
              <Profile />
            </Suspense>
          ),
        },
        {
          path: "/edit-profile/",
          element: (
            <Suspense fallback={<ProfileEditLoading />}>
              <EditProfile />
            </Suspense>
          ),
        },
        {
          path: "/my-wallet/:userId",
          element: (
            <Suspense fallback={<Overlay />}>
              <Wallet />
            </Suspense>
          ),
        },
        // Settings =================================================================
        {
          path: "/settings/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Settings />
            </Suspense>
          ),
        },
        {
          path: "/settings/account-control/",
          element: (
            <Suspense fallback={<Overlay />}>
              <AccountControl />
            </Suspense>
          ),
        },
        {
          path: "/settings/account-security/",
          element: (
            <Suspense fallback={<Overlay />}>
              <AccountSecurity />
            </Suspense>
          ),
        },
        {
          path: "/settings/verification/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Verifications />
            </Suspense>
          ),
        },
        {
          path: "/settings/blocked-users/",
          element: (
            <Suspense fallback={<Overlay />}>
              <BlockedUsers />
            </Suspense>
          ),
        },
        {
          path: "/settings/invite/",
          element: (
            <Suspense fallback={<Overlay />}>
              <InviteFriends />
            </Suspense>
          ),
        },
        {
          path: "/settings/notifications/",
          element: (
            <Suspense fallback={<Overlay />}>
              <NotificationsSettings />
            </Suspense>
          ),
        },
        {
          path: "/settings/account-privacy/",
          element: (
            <Suspense fallback={<Overlay />}>
              <PrivacySettings />
            </Suspense>
          ),
        },
        // Interactions ======================================
        {
          path: "/interactions/",
          element: (
            <Suspense fallback={<InteractionsLoading />}>
              <Interactions />
            </Suspense>
          ),
        },
        {
          path: "/notifications/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Notifications />
            </Suspense>
          ),
        },
        {
          path: "/networks/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Networks />
            </Suspense>
          ),
        },
        {
          path: "/networks/create-network",
          element: (
            <Suspense fallback={<Overlay />}>
              <CreateNetwork />
            </Suspense>
          ),
        },
        {
          path: "/networks/:networkId",
          element: (
            <Suspense fallback={<Overlay />}>
              <NetworkPage />
            </Suspense>
          ),
        },
        {
          path: "/network-details/:networkId",
          element: (
            <Suspense fallback={<Overlay />}>
              <NetworkDetails />
            </Suspense>
          ),
        },
        {
          path: "/edit-network/:networkId",
          element: (
            <Suspense fallback={<Overlay />}>
              <EditNetwork />
            </Suspense>
          ),
        },
        {
          path: "/compress",
          element: (
            <Suspense fallback={<Overlay />}>
              <Compress />
            </Suspense>
          ),
        },
        {
          path: "/messages/",
          element: (
            <Suspense fallback={<Overlay />}>
              <Chats />
            </Suspense>
          ),
        },
        {
          path: "/post/:postId",
          element: (
            <Suspense fallback={<Overlay />}>
              <PostDetails />
            </Suspense>
          ),
        },
        {
          path: "/hashtags/:tags",
          element: (
            <Suspense fallback={<PostsLoading />}>
              <Hashtags />
            </Suspense>
          ),
        },
        {
          path: "/menu",
          element: (
            <Suspense fallback={<Overlay />}>
              <Menu />
            </Suspense>
          ),
        },
        {
          path: "/support-inbox",
          element: (
            <Suspense fallback={<Overlay />}>
              <SupportInbox />
            </Suspense>
          ),
        },
        {
          path: "/personal-chat/:id",
          element: (
            <Suspense fallback={<Overlay />}>
              <PersonalChat />
            </Suspense>
          ),
        },
      ],
    },

    // other layouts
    {
      path: "/",
      element: <HelpLayouts />,
      children: [
        {
          path: "/blogs/:slug",
          element: (
            <Suspense fallback={<Overlay />}>
              <Blogs />
            </Suspense>
          ),
        },
        {
          path: "/contact-us",
          element: (
            <Suspense fallback={<Overlay />}>
              <ContactUs />
            </Suspense>
          ),
        },
        {
          path: "/join-testers-program",
          element: (
            <Suspense fallback={<Overlay />}>
              <AppTesters />
            </Suspense>
          ),
        },
        {
          path: "/help-center",
          element: (
            <Suspense fallback={<Overlay />}>
              <HelpCenter />
            </Suspense>
          ),
        },
        {
          path: "/help-center-menu",
          element: (
            <Suspense fallback={<Overlay />}>
              <HelpCenterMenu />
            </Suspense>
          ),
        },
        {
          path: "/about-us",
          element: (
            <Suspense fallback={<Overlay />}>
              <AboutUs />
            </Suspense>
          ),
        },
        {
          path: "/cookie-policy",
          element: (
            <Suspense fallback={<Overlay />}>
              <CookiePolicy />
            </Suspense>
          ),
        },
        {
          path: "/privacy-policy",
          element: (
            <Suspense fallback={<Overlay />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: "/contents-policy",
          element: (
            <Suspense fallback={<Overlay />}>
              <ContentsPolicy />
            </Suspense>
          ),
        },
        {
          path: "/terms-of-use",
          element: (
            <Suspense fallback={<Overlay />}>
              <TermsOfUse />
            </Suspense>
          ),
        },
        {
          path: "/adverts-and-ads-policy",
          element: (
            <Suspense fallback={<Overlay />}>
              <AdvertisementPolicy />
            </Suspense>
          ),
        },
        {
          path: "/app-tester-program-policy",
          element: (
            <Suspense fallback={<Overlay />}>
              <AppTesterPolicy />
            </Suspense>
          ),
        },
      ],
    },

    // General router link to all href links
    {
      path: "/download-app",
      element: (
        <Suspense fallback={<Overlay />}>
          <OpenBlikonnApp />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense fallback={<Overlay />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/signup",
      element: (
        <Suspense fallback={<Overlay />}>
          <Signup />
        </Suspense>
      ),
    },
    {
      path: "/reset-user-password",
      element: (
        <Suspense fallback={<Overlay />}>
          <ResetUserPassword />
        </Suspense>
      ),
    },
    {
      path: "/verify-code",
      element: (
        <Suspense fallback={<Overlay />}>
          <VerificationPage />
        </Suspense>
      ),
    },
    {
      path: "/recover-account",
      element: (
        <Suspense fallback={<Overlay />}>
          <RecoverAccount />
        </Suspense>
      ),
    },
    {
      path: "/create-new-password/",
      element: (
        <Suspense fallback={<Overlay />}>
          <CreateNewPassword />
        </Suspense>
      ),
    },
    {
      path: "/confirm-email/:confirmCode",
      element: (
        <Suspense fallback={<Overlay />}>
          <ConfirmEmails />
        </Suspense>
      ),
    },
    {
      // Wildcard route for unmatched paths
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

  // Load my app to be visible on the browser
  return (
    <div className="App">
      <Suspense fallback={<Overlay />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

export default App;
